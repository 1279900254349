import React from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import { useListContext } from './context/ListContext'
import Home from './pages/Home'


export const App = () => {
  const {shoppingList} = useListContext()
  let location = useLocation()

  const generatePath = (): string => {
    console.log(location.pathname.substring(1))
    if (location.pathname.substring(1)) {
      shoppingList.setId(location.pathname.substring(1))
      return shoppingList.getId
    }

    shoppingList.generateId()
    return shoppingList.getId
  }
  return (
    <>
      <Switch>
      <Route exact path="/">
          <Redirect to={'/' + generatePath()} />
      </Route>
        <Route path='/:id'>
          <Home />
        </Route>
      </Switch>
    </>
  )
}
